import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LanguageProvider } from "LanguageProvider";
import { fetchTranslations } from "translations/fetchTranslations";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider fetchTranslations={fetchTranslations}>
      <App />
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
