import React, { useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Form, Button, Col, Row, Spinner, Alert } from "react-bootstrap";
import { contactReducer } from "./reducers";
import { apiContactForm } from "api";
import { T } from "translations/T";

const initialData = {
  phone: {
    value: "",
    errors: [],
  },
  name: {
    value: "",
    errors: [],
  },
  emailTo: { value: "" },
};

function ConsultationForm({ product = null }) {
  const location = useLocation();
  const [contact, ContactDispatch] = useReducer(contactReducer, initialData);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendMessage = (event) => {
    event.preventDefault();

    setFormIsSubmitting(true);
    setFormSubmitted(false);
    ContactDispatch({ type: "reset_errors" });

    let submitData = {
      phone: contact.phone.value,
      name: contact.name.value,
      emailTo: contact.emailTo.value,
      product: product,
    };

    apiContactForm(submitData).then(
      () => {
        setFormSubmitted(true);
        setFormIsSubmitting(false);
        ContactDispatch({ type: "reset", payload: initialData });
      },
      (error) => {
        let error_dispatches = {
          name: "set_name_error",
          phone: "set_phone_error",
        };

        for (let key in error_dispatches) {
          if (key in error.data.errors) {
            ContactDispatch({
              type: error_dispatches[key],
              payload: error.data.errors[key],
            });
          }
        }
        setFormIsSubmitting(false);
      }
    );
  };

  location.pathname === "/" ||
  location.pathname.includes("/office") ||
  location.pathname.includes("/home")
    ? (contact.emailTo.value = "raimonds@4metri.lv")
    : location.pathname.includes("/event")
    ? (contact.emailTo.value = "aigars@4metri.lv")
    : (contact.emailTo.value = "normunds@4metri.lv");

  return (
    <Form onSubmit={sendMessage}>
      <Row>
        <Col className="d-none d-lg-block"></Col>
        <Col sm="6" lg="4" className="mb-3 p-0 p-sm-2">
          <Form.Group controlId="formPhone">
            <Form.Control
              type="hidden"
              name="email"
              onChange={(e) =>
                ContactDispatch({
                  type: "set_email_to",
                  payload: e.target.value,
                })
              }
              value={contact.emailTo.value}
            />
            <Form.Control
              type="tel"
              name="phone"
              placeholder="Tālruņa numurs..."
              onChange={(e) =>
                ContactDispatch({
                  type: "set_phone",
                  payload: e.target.value,
                })
              }
              isInvalid={contact.phone.errors.length > 0}
              value={contact.phone.value}
            />
            {contact.phone.errors.map((error, i) => (
              <Form.Control.Feedback type="invalid" key={i}>
                {error}
              </Form.Control.Feedback>
            ))}
          </Form.Group>
        </Col>
        <Col sm="6" lg="4" className="mb-3 p-0 p-sm-2">
          <Form.Group controlId="formName">
            <Form.Control
              type="text"
              name="name"
              placeholder="Vārds..."
              onChange={(e) =>
                ContactDispatch({
                  type: "set_name",
                  payload: e.target.value,
                })
              }
              isInvalid={contact.name.errors.length > 0}
              value={contact.name.value}
            />
            {contact.name.errors.map((error, i) => (
              <Form.Control.Feedback type="invalid" key={i}>
                {error}
              </Form.Control.Feedback>
            ))}
          </Form.Group>
        </Col>
        <Col className="d-none d-lg-block"></Col>
      </Row>
      <Row className="justify-content-center">
        <Button variant="custom" type="submit" disabled={formIsSubmitting}>
          <T>send</T>
          <Spinner
            animation="border"
            size="sm"
            variant="light"
            className="ml-2"
            hidden={!formIsSubmitting}
          />
        </Button>
      </Row>
      <Row hidden={!formSubmitted}>
        <Col>
          <Alert variant="success" className="mt-4">
            Ziņa ir nosūtīta! Tuvākajā laikā sazināsimies ar Jums.
          </Alert>
        </Col>
      </Row>
    </Form>
  );
}

export default ConsultationForm;
