import { Spinner } from "react-bootstrap";

function SpinnerLoader() {
  return (
    <>
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
      <Spinner animation="grow" />
    </>
  );
}

export default SpinnerLoader;
