import React from "react";
import { Row, Spinner } from "react-bootstrap";

function Loader(props) {
  return (
    <Row
      className="justify-content-center align-items-center my-auto"
      style={{ minHeight: "20px" }}
    >
      <Spinner animation="border" size={props.size} variant="light" />
    </Row>
  );
}

export default Loader;
