import React, { useState, useEffect } from "react";
import YellowContainer from "app/components/YellowContainer";
import CategoryAnimation from "./sections/category_section/CategoryAnimation";
import ConsultationModal from "multiuse/modals/ConsultationModal";
import ConsultationButton from "multiuse/consultant/Button";
import Consultant from "multiuse/consultant/Consultant";
import Clients from "./sections/clients/Clients";
import Slider from "./sections/clients/components/Slider";
import { T } from "translations/T";
import Tags from "multiuse/helmet/MetaTags";
import { apiClients } from "api";
import { Col, Row } from "react-bootstrap";
import "./style.scss";

function Index() {
  const [hover_1, setHover1] = useState(false);
  const [hover_2, setHover2] = useState(true);
  const [hover_3, setHover3] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [loadingClient, setLoadingClient] = useState(true);
  const [clients, setClient] = useState([]);

  useEffect(() => {
    setLoadingClient(true);

    apiClients().then((data) => {
      setClient(data);
      setLoadingClient(false);
    });
  }, []);

  return (
    <YellowContainer>
      <Tags />
      <Consultant
        category="all"
        hover1={hover_1}
        hover2={hover_2}
        hover3={hover_3}
      />
      <CategoryAnimation
        hover1={hover_1}
        hover2={hover_2}
        hover3={hover_3}
        setHover_1={setHover1}
        setHover_2={setHover2}
        setHover_3={setHover3}
      />
      <div className="pb-5">
        <ConsultationButton show={setModalShow} />
        <ConsultationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
      <Clients />
      {clients.length > 0 ? (
        <Row className="clients-slider justify-content-center">
          <Col xs="12" className="text-center">
            <h1 className="mt-4 mb-5 client-list-title ">
              <T>client_list</T>
            </h1>
          </Col>
          <Col>
            <Slider ready={!loadingClient} clients={clients} />
          </Col>
        </Row>
      ) : null}
    </YellowContainer>
  );
}

export default Index;
