import React from "react";
import info from "info";
import { useParams } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import Img from "images/logo/logo.png";

function Tags() {
  const { category } = useParams();
  let title =
    category === "office"
      ? info.tags.office_title
      : category === "home"
      ? info.tags.home_title
      : category === "events"
      ? info.tags.event_title
      : info.tags.index_title;

  let content =
    category === "office"
      ? info.tags.office_description
      : category === "home"
      ? info.tags.home_description
      : category === "events"
      ? info.tags.event_description
      : info.tags.index_description;
  return (
    <MetaTags>
      <title>{title}</title>
      <meta name="description" content={content} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={Img} />
    </MetaTags>
  );
}

export default Tags;
