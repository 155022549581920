import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Consultant from "multiuse/consultant/Consultant";
import ConsultationButton from "multiuse/consultant/Button";
import ConsultationModal from "multiuse/modals/ConsultationModal";
import TwentyTwenty from "./components/TwentyTwenty";
import info from "info";
import Tags from "multiuse/helmet/MetaTags";
import Products from "./components/Products";
import "./style.scss";
import YellowContainer from "app/components/YellowContainer";

function CareProducts() {
  const [modalShow, setModalShow] = useState(false);
  let category = "care-products";
  return (
    <YellowContainer className="category care-products">
      <Row className="justify-content-center">
        <Tags
          title={info.tags.care_products_title}
          content={info.tags.care_products_description}
        />
        <Col>
          <Consultant category={category} />
          <div className="pb-5">
            <ConsultationButton show={setModalShow} />
            <ConsultationModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
          <Col className="d-none d-sm-flex pt-5">
            <TwentyTwenty />
          </Col>
          <Products category={category} />
        </Col>
      </Row>
    </YellowContainer>
  );
}

export default CareProducts;
