import React, { useContext } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import ConsultationForm from "./components/Form";
import ModalContacts from "multiuse/modals/components/ModalContacts";
import { T } from "translations/T";
import info from "info";
import { LanguageContext } from "LanguageProvider";
import ImgContainer from "multiuse/ImgContainer";

function ProductCareModal({ show, onHide, productId, products, category }) {
  const { language } = useContext(LanguageContext);

  return products.map((product) =>
    productId === product.id ? (
      <Modal
        key={product.id}
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="product-care-modal"
        centered
        className="consultation-modal product-care-modal"
        closeButton
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="product-care-modal">
            {product.title ? product.title : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="4" className="text-center">
              <ImgContainer
                src={info.backend.storage_url + product.img_src}
                alt={product.img_alt}
                className="img-fluid"
              />
            </Col>
            <Col>
              {language === "lv"
                ? product.desc_lv
                  ? checkIfLineBreak(product.desc_lv)
                  : null
                : product.desc_ru
                ? checkIfLineBreak(product.desc_ru)
                : product.desc_lv
                ? checkIfLineBreak(product.desc_lv)
                : null}
              {product.volume ? (
                <p>
                  <strong>
                    <T>volume</T>:
                  </strong>{" "}
                  {product.volume}
                </p>
              ) : null}
              {product.consumption ? (
                <p>
                  <strong>
                    <T>consumption</T>:
                  </strong>{" "}
                  {product.consumption}
                </p>
              ) : null}
            </Col>
          </Row>
          <hr></hr>
          <p>
            <T>product_modal_contact_text</T>
          </p>
          <ModalContacts category={category} />
          <ConsultationForm product={product} />
        </Modal.Body>
      </Modal>
    ) : null
  );
}

export default ProductCareModal;

const checkIfLineBreak = (text) => {
  return text.split("\r\n").map((str) => <p key="str">{str}</p>);
};

export { checkIfLineBreak };
