import { Row } from "react-bootstrap";
import Loader from "multiuse/Loader";
import Message from "multiuse/Message";
import { useQuery } from "react-query";
import { apiConsultant } from "api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

function MenuContacts(props) {
  let category = "events";
  const { isLoading, error, data } = useQuery(["Consultant" + category], () =>
    apiConsultant(category)
  );

  if (isLoading) {
    return <Loader size="md" className="my-3" />;
  }
  if (!data || error) {
    return <Message />;
  }

  return (
    <>
      <Row
        className={
          props.footer && props.xs ? "justify-content-center my-3" : "my-3"
        }
      >
        <a href={"mailto:" + data[0].email}>
          <i className="mr-3 contact-icon">
            <FontAwesomeIcon icon={faEnvelope} />
          </i>
        </a>
        <a href={"tel:" + data[0].phone}>
          <i className="contact-icon">
            <FontAwesomeIcon icon={faMobileAlt} />
          </i>
        </a>
      </Row>
    </>
  );
}

export default MenuContacts;
