import { Col } from "react-bootstrap";
import Loader from "multiuse/Loader";
import Message from "multiuse/Message";
import { T } from "translations/T";

function MenuAddress(props) {
  if (!props.ready) {
    return <Loader size="md" />;
  }
  if (!props.address) {
    return <Message />;
  }

  return (
    <>
      <div>
        <Col className="p-0 mb-2">
          <a href={props.address.office_map} target="_blank" rel="noreferrer">
            <T>office_address</T>: {props.address.office_address}
          </a>
        </Col>
        <Col className="p-0 mb-2">
          <a href={props.address.storage_map} target="_blank" rel="noreferrer">
            <T>warehouse_address</T>: {props.address.storage_address}
          </a>
        </Col>
      </div>
    </>
  );
}

export default MenuAddress;
