const contactReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return action.payload;
    case "set_phone":
      return { ...state, phone: { ...state.phone, value: action.payload } };
    case "set_email_to":
      return { ...state, emailTo: { ...state.emailTo, value: action.payload } };
    case "set_name":
      return { ...state, name: { ...state.name, value: action.payload } };

    case "reset_errors":
      return {
        ...state,
        name: { ...state.name, errors: [] },
        phone: { ...state.phone, errors: [] },
      };
    case "set_phone_error":
      return { ...state, phone: { ...state.phone, errors: action.payload } };
    case "set_name_error":
      return { ...state, name: { ...state.name, errors: action.payload } };

    default:
      return state;
  }
};

export { contactReducer };
