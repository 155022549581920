import { Row, Col } from "react-bootstrap";
import img1 from "images/category_img/event-product.jpg";
import Products from "../multiuse/product_page/Products";
import ParquetProduct from "./components/parquet/ParquetProduct";
import CustomBreadcrumb from "multiuse/Breadcrumbs";
import YellowContainer from "./components/YellowContainer";
import { useParams } from "react-router-dom";
import info from "info";
import "./products.scss";
import { apiTypeData } from "api";
import { useQuery } from "react-query";
import ErrorMessage from "multiuse/ErrorMessage";
import ImgContainer from "multiuse/ImgContainer";

function ProductPage() {
  const { category, subCategoryId, typeId } = useParams();
  var isEvents = typeId ? false : true;
  const { isLoading, error, data } = useQuery(["type_data", typeId], () =>
    typeId
      ? apiTypeData(typeId, isEvents)
      : apiTypeData(subCategoryId, isEvents)
  );

  if (category === "parquet") {
    return (
      <YellowContainer className="parquet-product">
        <CustomBreadcrumb />
        <ParquetProduct />
      </YellowContainer>
    );
  }

  if (isLoading) {
    return (
      <YellowContainer className="product-page">
        <CustomBreadcrumb />
        <Row></Row>
      </YellowContainer>
    );
  }
  if (error) {
    return (
      <YellowContainer className="product-page">
        <CustomBreadcrumb />
        <ErrorMessage />
      </YellowContainer>
    );
  }

  return (
    <YellowContainer className="product-page">
      <CustomBreadcrumb />
      <Row>
        <Col className="d-none d-md-block">
          <ImgContainer
            src={data.img_src ? info.backend.storage_url + data.img_src : img1}
            alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
            className="img-fluid"
          />
        </Col>
        <Products title={data.title} description={data.description} />
      </Row>
    </YellowContainer>
  );
}

export default ProductPage;
