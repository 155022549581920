import ParquetItem from "./ParquetItem";
import { apiParquetData } from "api";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

function ParquetProduct() {
  const { subCategoryId, typeId } = useParams();
  let id;

  if (typeId === undefined) {
    id = "null";
  } else {
    id = typeId;
  }

  const { isLoading, error, data } = useQuery(
    ["parquetData" + subCategoryId + id],
    () => apiParquetData(subCategoryId, id)
  );
  return <ParquetItem data={data} isLoading={isLoading} error={error} />;
}
export default ParquetProduct;
