import axios from "axios";
import info from "info";

/**
 * API GET request
 * Use with React Query quries: https://react-query.tanstack.com/guides/queries
 * @param {string} url
 * @param {string} language
 * @returns {(Object|Array|string)}
 */
const GET = (url) => {
  return axios.get(info.api.API_ENDPOINT + url).then(function (response) {
    return response.data;
  });
};

export { GET };

// {
//     headers: { "Accept-Language": language || config.DEFAULT_LANGUAGE },
//   }
