import { Row, Col } from "react-bootstrap";
import GoogleMap from "multiuse/Maps";
import YellowContainer from "app/components/YellowContainer";
import info from "info";
import Tags from "multiuse/helmet/MetaTags";
import BusinessInfo from "./components/BusinessInfo";
import "./style.scss";

function Contacts() {
  return (
    <YellowContainer className="category contacts">
      <Tags
        title={info.tags.contacts_title}
        content={info.tags.contacts_description}
      />
      <Row>
        <Col xl="5" style={{ maxHeight: "30rem" }} className="mt-3">
          <GoogleMap />
        </Col>
        <hr></hr>
        <Col>
          <hr></hr>
          <BusinessInfo />
        </Col>
      </Row>
    </YellowContainer>
  );
}
export default Contacts;
