import { Col, Card } from "react-bootstrap";
import info from "info";
import ImgContainer from "multiuse/ImgContainer";

function Item(props) {
  return (
    <Col
      xs="12"
      className="d-flex justify-content-center align-items-center h-100"
    >
      <Card style={{ minHeight: "10rem" }} className="alice-slider-card">
        <ImgContainer
          src={info.backend.storage_url + props.img}
          alt={props.alt}
          className="m-auto"
          style={{ maxWidth: "150px" }}
        />
      </Card>
    </Col>
  );
}

export default Item;
