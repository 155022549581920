import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Item from "./components/Item";
import Consultant from "multiuse/consultant/Consultant";
import ConsultationButton from "multiuse/consultant/Button";
import ConsultationModal from "multiuse/modals/ConsultationModal";
import info from "info";
import Tags from "multiuse/helmet/MetaTags";
import "./style.scss";
import YellowContainer from "app/components/YellowContainer";

function Tools() {
  let category = "tools";
  const [modalShow, setModalShow] = useState(false);

  return (
    <YellowContainer className="category">
      <Row className="justify-content-center">
        <Tags
          title={info.tags.tools_title}
          content={info.tags.tools_description}
        />
        <Col xs={12}>
          <Consultant category={category} />
          <div className="pb-5">
            <ConsultationButton show={setModalShow} />
            <ConsultationModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </Col>
        <Col xs={12}>
          <Row>
            <Item />
          </Row>
        </Col>
      </Row>
    </YellowContainer>
  );
}

export default Tools;
