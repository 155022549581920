import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import routes from "routes";
import Header from "partials/header/Header";
import Index from "pages/index/Index";
import Category from "app/Category";
import TypePage from "app/components/TypePage";
import ProductPage from "app/ProductPage";
import Footer from "partials/footer/Footer";
import Error404 from "errors/Error404";
import "app/utils.scss";
import Tools from "pages/tools/Tools";
import Contacts from "pages/contacts/Contacts";
import "react-lazy-load-image-component/src/effects/blur.css";
import CareProducts from "pages/care_products/CareProducts";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div>
          <ScrollToTop />
          <Header />
          <Switch>
            <Route path={routes.index} exact component={Index}></Route>
            <Route path={routes.tools} exact component={Tools}></Route>
            <Route path={routes.contacts} exact component={Contacts}></Route>
            <Route path={routes.care} exact component={CareProducts}></Route>
            <Route path={routes.section} exact component={Category}></Route>
            {/* <Route path="*" component={Error404} /> */}
            <Route path={routes.types} exact component={TypePage} />
            <Route path={routes.products} exact component={ProductPage} />
            <Route path="*" component={Error404} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </QueryClientProvider>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
