import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImg from "images/placeholder.png";

function ImgContainer({ src, alt, ...rest }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="position-relative">
      {isLoading ? (
        <div className="position-absolute w-100 h-100" style={{ zIndex: 100 }}>
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner animation="border" size="sm" variant="light" />
          </div>
        </div>
      ) : null}
      <LazyLoadImage
        {...rest}
        effect="blur"
        placeholderSrc={placeholderImg}
        src={src}
        alt={alt}
        afterLoad={() => {
          setIsLoading(false);
        }}
      />
    </div>
  );
}

export default ImgContainer;
