const routes = {
  index: "/",
  tools: "/tools",
  contacts: "/contacts",
  care: "/care-products",
  section: "/:category",
  types: "/:category/:subCategory/:subCategoryId",
  products: "/:category/:subCategory/:subCategoryId/:type/:typeId",
};

export default routes;
