import React from "react";
import { Row } from "react-bootstrap";

function Message() {
  return (
    <Row
      className="align-items-center justify-content-center"
      style={{ minHeight: "20px", width: "100%" }}
    >
      <p
        className="text-left"
        style={{ color: "white", letterSpacing: ".9px" }}
      >
        Neesam vēl pievienojuši informāciju!
      </p>
    </Row>
  );
}
export default Message;
