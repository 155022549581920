import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import Loader from "multiuse/Loader";
import Consultant from "./Consultant";
import { T } from "translations/T";
import Address from "./Address";
import Message from "multiuse/Message";
import ErrorMessage from "multiuse/ErrorMessage";
import { apiBusinessInfo } from "api";
import { useQuery } from "react-query";

function BusinessInfo() {
  const { isLoading, error, data } = useQuery(["BusinessInfo"], () =>
    apiBusinessInfo()
  );

  if (error) {
    return (
      <div className="contacts-right p-2 p-md-4 mt-3">
        <ErrorMessage />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="contacts-right p-2 p-md-4 mt-3">
        <Loader size="md" />
      </div>
    );
  }
  return (
    <div className="contacts-right py-2 py-md-4 mt-3">
      <Row>
        {data["consultants"].map((cons, index) => (
          <>
            <Consultant
              key={index}
              name={cons.name}
              phone={cons.phone}
              email={cons.email}
            />
          </>
        ))}
      </Row>
      <Row>
        <Col md="6">
          <Address
            title="office_address"
            gm={data["address"].office_map}
            address={data["address"].office_address}
          />
        </Col>
        <Col md="6">
          <Address
            gm={data["address"].storage_map}
            address={data["address"].storage_address}
            title="warehouse_address"
          />
        </Col>
      </Row>
      <hr></hr>
      <Row>
        {data["openhours"].length > 0 ? (
          <Col className="text-center text-md-right">
            <p className="d-block d-md-flex align-items-center justify-content-end title">
              <T>open_hours</T>
              <i className="m-auto mx-md-0 ml-md-2 ml-3">
                <FontAwesomeIcon icon={faClock} />
              </i>
            </p>
            {data["openhours"].map((openhours, index) => (
              <Row
                key={index}
                className="d-block d-md-flex justify-content-end title times"
              >
                <p className="mr-0 mr-md-5 mb-0 text-md-right text-center">
                  {openhours.day}
                </p>
                <p className="text-md-right text-center">
                  {RemoveZeros(openhours.start_time)}-
                  {RemoveZeros(openhours.end_time)}
                </p>
              </Row>
            ))}
          </Col>
        ) : (
          <Message />
        )}
      </Row>
    </div>
  );
}

const RemoveZeros = (string) => {
  return string.slice(0, 5);
};

export default BusinessInfo;
