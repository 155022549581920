import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import bed from "images/parquet/bed.png";
import info from "info";
import "./style.scss";
import ImgContainer from "multiuse/ImgContainer";

function ParquetBed() {
  const [toggle, setToggle] = useState("img1");
  return (
    <Row
      className="text-center d-none d-sm-flex parquet-bed"
      style={{ minHeight: "25rem" }}
    >
      <Col className="img-wrap">
        {info.parquet_img.map(({ big, id }) => (
          <img
            key={id}
            id={id}
            src={big}
            alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
            className={toggle === id ? "floor-img active" : "floor-img"}
          ></img>
        ))}
        <div>
          <ImgContainer
            style={{
              minHeight: "11rem",
              position: "relative",
              width: "100%",
            }}
            src={bed}
            alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
          />
        </div>
        <Row className="justify-content-center nav-wrap">
          {info.parquet_img.map(({ small, id }) => (
            <div
              key={id}
              id={id}
              className="pr-1 parquet-nav"
              onClick={() => setToggle(id)}
            >
              <img
                src={small}
                className={toggle === id ? "img-fluid active" : "img-fluid"}
                alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
              ></img>
            </div>
          ))}
        </Row>
      </Col>
    </Row>
  );
}

export default ParquetBed;
