import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { apiAddress } from "api";
import MenuAddress from "partials/header/components/mobile_menu/components/MenuAddress";
import MenuContacts from "partials/header/components/mobile_menu/components/MenuContacts";
import { T } from "translations/T";
import info from "info";
import "./style.scss";
import GoogleMap from "multiuse/Maps";

function Footer() {
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [address, setAddress] = useState([]);

  const [mobile, setMobile] = useState(false);

  const addMobile = () => {
    if (window.innerWidth < 577) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    setLoadingAddress(true);

    apiAddress().then((data) => {
      setAddress(data);
      setLoadingAddress(false);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", addMobile);
    return () => window.removeEventListener("resize", addMobile);
  }, []);

  return (
    <footer className="footer pt-5">
      <hr></hr>
      <Container>
        <Row className="footer-wrap">
          <Col md="6" className="footer-left mb-2">
            <Row>
              <Col sm="6" md="7">
                <Row>
                  <Col xs="12" className="p-0 text-center text-sm-left">
                    <h6>
                      <T>address</T>
                    </h6>
                    <MenuAddress ready={!loadingAddress} address={address} />
                  </Col>
                  <Col className="p-0 text-center text-sm-left">
                    <h6>
                      <T>contacts</T>
                    </h6>
                    <MenuContacts
                      footer={true}
                      xs={mobile || window.innerWidth < 577 ? true : false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="text-center text-sm-left">
                <h6>
                  <T>floor_coating</T>
                </h6>
                {info.pages.map((page, index) => (
                  <Col key={index} className="p-0">
                    <a href={page.url}>
                      <T>{page.translation}</T>
                    </a>
                  </Col>
                ))}
              </Col>
            </Row>
          </Col>
          <Col className="footer-right mt-3">
            <GoogleMap />
          </Col>
        </Row>
        <hr className="mb-2"></hr>
        <Row className="pb-3">
          <Col xs="12">
            <p>
              <T>page_design</T> © {getYear()} SIA 4metri{" "}
            </p>
          </Col>
          <Col>
            <p>
              <T>developers</T>
              <a
                href="https://babrisoftware.com/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Babri Software
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

function getYear() {
  return new Date().getFullYear();
}
export default Footer;
