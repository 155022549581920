import Content from "./Content";
import { useParams } from "react-router-dom";
import YellowContainer from "app/components/YellowContainer";
import ProductPage from "app/ProductPage";

function TypePage() {
  const { category } = useParams();

  if (category === "events") {
    return <ProductPage />;
  }

  return (
    <YellowContainer className="category">
      <Content />
    </YellowContainer>
  );
}

export default TypePage;
