import React from "react";
import { Row } from "react-bootstrap";

function ErrorMessage() {
  return (
    <Row
      className="align-items-center justify-content-center"
      style={{ minHeight: "20px", width: "100%" }}
    >
      <p
        className="text-left"
        style={{ color: "white", letterSpacing: ".9px" }}
      >
        Notika kļūda. Lūdzu, mēģiniet pārlādēt lapu.
      </p>
    </Row>
  );
}
export default ErrorMessage;
