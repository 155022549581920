import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function YellowContainer({ children, className = "" }) {
  const { pathname } = useLocation();
  return (
    <Container fluid className={"bc-yellow p-0 " + className}>
      <Container
        fluid={pathname === "/" ? true : false}
        id="/"
        className="page-wrapper"
      >
        {children}
      </Container>
    </Container>
  );
}

export default YellowContainer;
