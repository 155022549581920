import { Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { apiAllSubcategories } from "api";
import { T } from "translations/T";
import info from "info";
import Message from "multiuse/Message";
import Spinner from "multiuse/SpinnerLoader";
import ImgContainer from "multiuse/ImgContainer";

const SubCategories = ({ category }) => {
  const { isLoading, error, data } = useQuery(["section_" + category], () =>
    apiAllSubcategories(category)
  );

  if (isLoading) {
    return (
      <Col
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ opacity: 0.6, minHeight: "22rem" }}
      >
        <Spinner />
      </Col>
    );
  }

  if (error) {
    return (
      <Col
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ minHeight: "22rem" }}
      >
        <Message />
      </Col>
    );
  }

  return data.map((item) =>
    item.featured ? (
      <Col
        key={item.id}
        md={category === "home" ? 6 : category === "events" ? 6 : 4}
        className="p-0 pr-1"
      >
        <Link
          to={
            item.hasTypes
              ? item.slug + "/" + item.id
              : item.slug +
                "/" +
                item.id +
                item.types[0].slug +
                "/" +
                item.types[0].id
          }
        >
          <Card
            className="text-white m-0 mb-1 bg-dark hover-card"
            style={{ minHeight: "11rem" }}
          >
            <ImgContainer
              style={{ minHeight: "11rem" }}
              src={info.backend.storage_url + item.img_src}
              alt={item.img_alt}
            />
            <Card.ImgOverlay className="d-flex align-items-center justify-content-center">
              <Card.Title className="m-0">
                <T>{item.translation}</T>
              </Card.Title>
            </Card.ImgOverlay>
          </Card>
        </Link>
      </Col>
    ) : null
  );
};

export default SubCategories;
