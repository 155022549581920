import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { apiAllTypes } from "api";
import { useQuery } from "react-query";
import ConsultationModal from "multiuse/modals/ConsultationModal";
import ConsultationButton from "multiuse/consultant/Button";
import Consultant from "multiuse/consultant/Consultant";
import CustomBreadcrumb from "multiuse/Breadcrumbs";
import TypeItem from "app/components/TypeItem";
import Message from "multiuse/Message";
import Spinner from "multiuse/SpinnerLoader";
import { useParams } from "react-router-dom";

function Content() {
  const [modalShow, setModalShow] = useState(false);

  const { category, subCategoryId } = useParams();

  const { isLoading, error, data } = useQuery(["Types", subCategoryId], () =>
    apiAllTypes(subCategoryId)
  );

  if (isLoading) {
    return (
      <Col
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ opacity: 0.6, minHeight: "22rem" }}
      >
        <Spinner />
      </Col>
    );
  }

  if (error || data.length === 0) {
    return (
      <>
        <div className="pb-5">
          <Consultant category={category} />
          <ConsultationButton show={setModalShow} />
        </div>
        <ConsultationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <CustomBreadcrumb />
        <Row className="bg-op-brown align-items-center py-2">
          <Col
            className="d-flex w-100 align-items-center justify-content-center"
            style={{ minHeight: "2rem" }}
          >
            <Message />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <div className="pb-5">
        <Consultant category={category} />
        <ConsultationButton show={setModalShow} />
      </div>
      <ConsultationModal show={modalShow} onHide={() => setModalShow(false)} />
      <CustomBreadcrumb />
      <Row className="bg-op-brown align-items-center py-2">
        {data.map((type) =>
          type.featured ? (
            <TypeItem
              key={type.id}
              title={type.title}
              slug={type.slug}
              id={type.id}
              isFeatured={type.featured}
            />
          ) : null
        )}
      </Row>
    </>
  );
}

export default Content;
