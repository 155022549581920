import { Row, Col, Carousel } from "react-bootstrap";
import Message from "multiuse/Message";
import Loader from "multiuse/Loader";
import ConsultantContact from "./icons/Contacts";
import { apiConsultant } from "api";
import { useQuery } from "react-query";
import { T } from "translations/T";
import info from "info";
import "./style.scss";
import ImgContainer from "multiuse/ImgContainer";

function Consultant({ category, hover1, hover2, hover3 }) {
  const { isLoading, error, data } = useQuery(["Consultant" + category], () =>
    apiConsultant(category)
  );

  if (isLoading) {
    return (
      <Row className="justify-content-center" style={{ minHeight: "130px" }}>
        <Loader size="md" />
      </Row>
    );
  }
  if (error) {
    return <Message />;
  }
  if (category !== "all") {
    return (
      <>
        {data.map((cons, index) => (
          <Row
            key={index}
            style={{ minHeight: "130px" }}
            className="consultant fade-in"
          >
            <Col
              xs={{ order: 2, span: 12 }}
              md={{ order: 1, span: 5 }}
              className="text-center text-md-right m-auto"
            >
              <p>
                <T>consultant_text_1</T>
                {cons.name}.
              </p>
              <p>
                <strong>
                  {category === "events" ||
                  category === "office" ||
                  category === "home" ? (
                    <T>consultant_text_2_1</T>
                  ) : null}
                  {category === "events" ? (
                    <T>consultant_text_2_3</T>
                  ) : category === "parquet" ? (
                    <T>consultation_parquet</T>
                  ) : category === "tools" ? (
                    <T>consultant_tools</T>
                  ) : category === "care-products" ? (
                    <T>consultant_celaning</T>
                  ) : (
                    <T>consultant_text_2_2</T>
                  )}
                </strong>
              </p>
            </Col>
            <Col
              xs={{ order: 1, span: 12 }}
              md={{ order: 2, span: 2 }}
              className="borders-on-sides m-auto"
            >
              <div className="img-wrapper text-center">
                <ImgContainer
                  src={info.backend.storage_url + cons.img_src}
                  alt={cons.img_alt}
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col
              xs={{ order: 3, span: 12 }}
              md={{ order: 3, span: 5 }}
              className="text-center text-md-left m-auto"
            >
              <ConsultantContact
                email={cons.email}
                phone={cons.phone}
                ready={isLoading}
              />
            </Col>
          </Row>
        ))}
      </>
    );
  }

  return (
    <>
      <Carousel
        interval={3000}
        indicators={false}
        controls={true}
        className="d-block d-sm-none"
      >
        {data.map((cons, index) => (
          <Carousel.Item key={index}>
            <Row
              style={{ minHeight: "130px" }}
              className={
                hover1 && cons.name === "Raimonds"
                  ? "consultant fade-in"
                  : hover2 && cons.name === "Raimonds"
                  ? "consultant fade-in"
                  : hover3 && cons.name === "Aigars"
                  ? "consultant fade-in"
                  : "consultant d-sm-none"
              }
            >
              <Col
                xs={{ order: 2, span: 12 }}
                md={{ order: 1, span: 5 }}
                className="text-center text-md-right m-auto"
              >
                <p>
                  <T>consultant_text_1</T>
                  {cons.name}.
                </p>
                <p>
                  <strong>
                    {cons.name === "Aigars" ? (
                      <>
                        <T>consultant_text_2_1</T>
                        <T>consultant_text_2_3</T>
                      </>
                    ) : cons.name === "Raimonds" ? (
                      <>
                        <T>consultant_text_2_1</T>
                        <T>consultant_text_2_2</T>
                      </>
                    ) : (
                      <T>consultant_text_3</T>
                    )}
                  </strong>
                </p>
              </Col>
              <Col
                xs={{ order: 1, span: 12 }}
                md={{ order: 2, span: 2 }}
                className="borders-on-sides m-auto"
              >
                <div className="img-wrapper text-center">
                  <ImgContainer
                    src={info.backend.storage_url + cons.img_src}
                    alt={cons.img_alt}
                    className="img-fluid"
                  />
                </div>
              </Col>
              <Col
                xs={{ order: 3, span: 12 }}
                md={{ order: 3, span: 5 }}
                className="text-center text-md-left m-auto"
              >
                <ConsultantContact
                  email={cons.email}
                  phone={cons.phone}
                  ready={isLoading}
                />
              </Col>
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="d-none d-sm-block">
        {data.map((cons, index) => (
          <Row
            key={index}
            style={{ minHeight: "130px" }}
            className={
              hover1 && cons.name === "Raimonds"
                ? "consultant fade-in"
                : hover2 && cons.name === "Raimonds"
                ? "consultant fade-in"
                : hover3 && cons.name === "Aigars"
                ? "consultant fade-in"
                : "consultant d-sm-none"
            }
          >
            <Col
              xs={{ order: 2, span: 12 }}
              md={{ order: 1, span: 5 }}
              className="text-center text-md-right m-auto"
            >
              <p>
                <T>consultant_text_1</T>
                {cons.name}.
              </p>
              <p>
                <strong>
                  <T>consultant_text_2_1</T>
                  {cons.name === "Aigars" ? (
                    <T>consultant_text_2_3</T>
                  ) : (
                    <T>consultant_text_2_2</T>
                  )}
                </strong>
              </p>
            </Col>
            <Col
              xs={{ order: 1, span: 12 }}
              md={{ order: 2, span: 2 }}
              className="borders-on-sides m-auto"
            >
              <div className="img-wrapper text-center">
                <ImgContainer
                  src={info.backend.storage_url + cons.img_src}
                  alt={cons.img_alt}
                  className="img-fluid"
                />
              </div>
            </Col>
            <Col
              xs={{ order: 3, span: 12 }}
              md={{ order: 3, span: 5 }}
              className="text-center text-md-left m-auto"
            >
              <ConsultantContact
                email={cons.email}
                phone={cons.phone}
                ready={isLoading}
              />
            </Col>
          </Row>
        ))}
      </div>
    </>
  );
}

export default Consultant;
