import { Col, Button } from "react-bootstrap";
import { T } from "translations/T";

function ConsultationButton(props) {
  return (
    <Col xs="12" className="text-center mt-5">
      <Button
        onClick={() => props.show(true)}
        className="consultation-modal-button"
      >
        <T>consultation_title</T>
      </Button>
    </Col>
  );
}

export default ConsultationButton;
