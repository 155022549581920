import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { apiConsultant } from "api";
import { useQuery } from "react-query";
import Loader from "multiuse/Loader";

function ModalContacts({ category }) {
  const { isLoading, error, data } = useQuery(["Consultant" + category], () =>
    apiConsultant(category)
  );

  if (isLoading) {
    return <Loader size="md" className="my-3" />;
  }

  if (error) {
    return <div>Error...</div>;
  }

  return (
    <>
      {data.map((cons, index) =>
        (category === "care-products" || category === "parquet") &&
        cons.name === "Normunds" ? (
          <Row key={index} className="my-3 align-items-center">
            <a href={"tel:" + cons.phone}>
              <i className="mr-2 contact-icon">
                <FontAwesomeIcon icon={faMobileAlt} />
              </i>
            </a>
            <span className="d-none d-sm-block">{cons.phone}</span>
            <a href={"mailto:" + cons.email}>
              <i className="mx-2 contact-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </i>
            </a>
            <span className="d-none d-sm-block">{cons.email}</span>
          </Row>
        ) : category === "events" && cons.name === "Aigars" ? (
          <Row key={index} className="my-3 align-items-center">
            <a href={"tel:" + cons.phone}>
              <i className="mr-2 contact-icon">
                <FontAwesomeIcon icon={faMobileAlt} />
              </i>
            </a>
            <span className="d-none d-sm-block">{cons.phone}</span>
            <a href={"mailto:" + cons.email}>
              <i className="mx-2 contact-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </i>
            </a>
            <span className="d-none d-sm-block">{cons.email}</span>
          </Row>
        ) : (category === "home" || category === "office") &&
          cons.name === "Raimonds" ? (
          <Row key={index} className="my-3 align-items-center">
            <a href={"tel:" + cons.phone}>
              <i className="mr-2 contact-icon">
                <FontAwesomeIcon icon={faMobileAlt} />
              </i>
            </a>
            <span className="d-none d-sm-block">{cons.phone}</span>
            <a href={"mailto:" + cons.email}>
              <i className="mx-2 contact-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </i>
            </a>
            <span className="d-none d-sm-block">{cons.email}</span>
          </Row>
        ) : null
      )}
    </>
  );
}

export default ModalContacts;
