import { Modal } from "react-bootstrap";
import ConsultationForm from "./components/Form";
import { T } from "translations/T";
import "./style.scss";

function ConsultationModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="consultation-modal"
      centered
      className="consultation-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="consultation-modal">
          <h4>
            <T>consultation_title</T>
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <T>consultation_text</T>
        </p>
        <ConsultationForm />
      </Modal.Body>
    </Modal>
  );
}

export default ConsultationModal;
