import React, { useContext } from "react";
import { Modal, Col, Row } from "react-bootstrap";
import ConsultationForm from "./components/Form";
import ModalContacts from "multiuse/modals/components/ModalContacts";
import { LanguageContext } from "LanguageProvider";
import { T } from "translations/T";
import info from "info";
import ImgContainer from "multiuse/ImgContainer";

function ProductModal({
  show,
  onHide,
  productId,
  products,
  pathname,
  category,
}) {
  const { language } = useContext(LanguageContext);

  return products.map((product) =>
    productId === product.id ? (
      <Modal
        key={product.id}
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="product-care-modal"
        centered
        className="consultation-modal product-modal"
        closeButton
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="product-care-modal">
            {pathname.includes("grass") ? null : product.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="12" className="p-0 img-wrap mb-4">
              {pathname.includes("grass") ||
              pathname.includes("design-studio") ? (
                <ImgContainer
                  src={info.backend.storage_url + product.descriptive_img_src}
                  wrapperClassName="img-fluid grass-img"
                  alt={product.descriptive_img_alt}
                />
              ) : (
                <ImgContainer
                  src={info.backend.storage_url + product.img_src}
                  wrapperClassName="img-fluid"
                  alt={product.img_alt}
                />
              )}
            </Col>
            <Col xs="12" className="p-0">
              {language === "lv"
                ? product.desc_lv
                  ? checkIfLineBreak(product.desc_lv)
                  : null
                : product.desc_ru
                ? checkIfLineBreak(product.desc_ru)
                : product.desc_lv
                ? checkIfLineBreak(product.desc_lv)
                : null}
            </Col>
            {product.width || product.style ? (
              <Col lg="5" className="p-0">
                {product.width ? (
                  <p>
                    <strong>
                      <T>width</T> (mm):
                    </strong>{" "}
                    {product.width}
                  </p>
                ) : null}
                {product.style ? (
                  <p>
                    <strong>
                      <T>style</T>:
                    </strong>{" "}
                    {product.style}
                  </p>
                ) : null}
              </Col>
            ) : null}
            {product.surface_coating || product.surface_treatment ? (
              <Col className="p-0">
                {product.surface_treatment ? (
                  <p>
                    <strong>
                      <T>surface_treatment</T>:
                    </strong>{" "}
                    {product.surface_treatment}
                  </p>
                ) : null}
                {product.surface_coating ? (
                  <p>
                    <strong>
                      <T>surface_coating</T>:
                    </strong>{" "}
                    {product.surface_coating}
                  </p>
                ) : null}
              </Col>
            ) : null}
          </Row>
          <hr></hr>
          <p>
            <T>product_modal_contact_text</T>
          </p>
          <ModalContacts category={category} />
          <ConsultationForm product={product} />
        </Modal.Body>
      </Modal>
    ) : null
  );
}

export default ProductModal;

const checkIfLineBreak = (text) => {
  return text.split("\r\n").map((str, index) => <p key={index}>{str}</p>);
};

export { checkIfLineBreak };
