import TwentyTwenty from "react-twentytwenty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import img1 from "images/care_products/1.png";
import img2 from "images/care_products/2.png";

function Twenty() {
  return (
    <TwentyTwenty
      left={
        <img
          src={img1}
          alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
          className="twenty-img"
        />
      }
      right={
        <img
          src={img2}
          alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
          className="twenty-img"
        />
      }
      slider={
        <div className="twenty-slider">
          <FontAwesomeIcon icon={faArrowsAltH} className="p-1" />
        </div>
      }
    />
  );
}

export default Twenty;
