import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import MenuAddress from "./components/MenuAddress";
import MenuContacts from "./components/MenuContacts";
// import { LanguageContext } from "LanguageProvider";
import { T } from "translations/T";
import { fallDown as Menu } from "react-burger-menu";
import info from "info";
import { apiAddress, apiConsultant } from "api";
import { useLocation } from "react-router-dom";
import "./style.scss";

function MobileMenu() {
  //   const { updateLanguage } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingConsultant, setLoadingConsultant] = useState(true);
  const [address, setAddress] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setLoadingConsultant(true);

    apiAddress().then((data) => {
      setAddress(data);
      setLoading(false);
    });

    apiConsultant("all").then((data) => {
      setConsultant(data);
      setLoadingConsultant(false);
    });
  }, []);

  useEffect(() => {
    if (open === true) {
      document.body.classList.toggle("scroll-lock");
    } else {
      document.body.classList.remove("scroll-lock");
    }
  }, [open]);

  return (
    <Menu
      className="mobile-menu"
      isOpen={open}
      onStateChange={(state) => setOpen(state.isOpen)}
    >
      <Col className="d-flex text-left mobile-lang mobile-menu-link px-0 mb-2">
        {/* <div
          className="pr-3"
          style={{ cursor: "pointer" }}
          onClick={() => updateLanguage("lv")}
        >
          LV
        </div>
        <div style={{ cursor: "pointer" }} onClick={() => updateLanguage("ru")}>
          RU
        </div> */}
      </Col>
      {/* <hr></hr> */}
      <Row className="text-left mobile-address-field">
        <Col className="p-0 mb-2 text-capitalize">
          <T>address</T>:
        </Col>
        <MenuAddress ready={!loading} address={address} />
        <Col className="p-0 my-2 text-capitalize">
          <T>contacts</T>:
        </Col>
        <Col className="p-0">
          <MenuContacts ready={!loadingConsultant} consultant={consultant} />
        </Col>
      </Row>
      <Row className="pb-3">
        <Col
          className={
            location.pathname === "/" ? "mobile-menu-title" : "mobile-menu-link"
          }
        >
          <a href="/">
            <T>beginning</T>
          </a>
        </Col>
        {info.pages.map((page, index) => (
          <Col
            key={index}
            className={
              location.pathname === page.url
                ? "mobile-menu-title"
                : "mobile-menu-link"
            }
          >
            <a href={page.url} className="px-1">
              <T>{page.translation}</T>
            </a>
          </Col>
        ))}
      </Row>
    </Menu>
  );
}

export default MobileMenu;
