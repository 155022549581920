import React from "react";
import { T } from "translations/T";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./style.scss";

function NavLink(props) {
  const location = useLocation();

  return (
    <Link
      to={props.url}
      className={
        location.pathname === props.url || location.pathname.includes(props.url)
          ? "header-link px-2 active"
          : "header-link px-2"
      }
    >
      <T>{props.name}</T>
    </Link>
  );
}

export default NavLink;
