import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { T } from "translations/T";
// import Loader from "multiuse/Loader";

function Address(props) {
  return (
    <Row>
      <Col className="title">
        <div className="d-flex align-items-center justify-content-center justify-content-sm-start">
          <a href={props.gm} target="_blank" rel="noreferrer" className="ml-0">
            <FontAwesomeIcon icon={faMapMarker} />
          </a>
          <span className="d-none d-sm-block">
            <T>{props.title}</T>
          </span>
        </div>
        <p className="text-center text-sm-left mt-3">{props.address}</p>
      </Col>
    </Row>
  );
}

export default Address;
