import React, { useState, useEffect, useRef, useContext } from "react";
import { Row, Col, Card, Button, CardGroup } from "react-bootstrap";
import ProductCareModal from "multiuse/modals/ProductCareModal";
import { T } from "translations/T";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { apiCareProducts } from "api";
import { useQuery } from "react-query";
import Isotope from "isotope-layout";
import { LanguageContext } from "LanguageProvider";
import info from "info";
import Loader from "multiuse/Loader";
import Message from "multiuse/Message";
import ImgContainer from "multiuse/ImgContainer";

function Products({ category }) {
  const { language } = useContext(LanguageContext);

  const { isLoading, error, data } = useQuery(["CareProducts"], () =>
    apiCareProducts()
  );

  const [show, setShow] = useState(false);
  const [productID, setProductID] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");

  // initialize an Isotope object with configs
  useEffect(() => {
    if (!isLoading && filterKey !== "*") {
      isotope.current = new Isotope(
        ".filter-container",
        {
          itemSelector: ".filter-item",
          layoutMode: "fitRows",
        },
        [isLoading]
      );
      // cleanup
      return () => isotope.current.destroy();
    }
  }, [isLoading, filterKey]);

  // handling filter key change
  useEffect(() => {
    if (!isLoading && filterKey !== "*") {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, isLoading]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  if (isLoading) {
    return (
      <Col
        className="d-flex justify-content-center rounded mt-5"
        style={{ minHeight: "3.5rem", backgroundColor: "rgba(0, 0, 0, 0.6)" }}
      >
        <Loader size="md" />
      </Col>
    );
  }
  if (error) {
    return (
      <Col xs="12" className="mt-5">
        <Message />
      </Col>
    );
  }
  return (
    <Col xs="12" className="">
      <Row className="mt-4 justify-content-center">
        {data["types"].map((item, index) =>
          item.deleted_at === null ? (
            <Col key={index} className="d-flex justify-content-center p-0 mr-1">
              <button
                onClick={handleFilterKeyChange("type_" + item.id)}
                className={
                  filterKey === "type_" + item.id
                    ? "active px-2 text-white mr-1 btn btn-lg text-uppercase bg-brown w-100"
                    : "px-2 text-white mr-1 btn btn-lg text-uppercase bg-brown w-100"
                }
              >
                <T>{item.title}</T>
              </button>
            </Col>
          ) : null
        )}
      </Row>
      <div
        className={
          filterKey === "*"
            ? "d-none"
            : "filter-container mt-4 d-flex justify-content-center"
        }
      >
        <CardGroup className="h-100 w-100">
          {data["types"].map((type) =>
            data["products"].map((product, index) =>
              product.type_id === type.id &&
              product.featured &&
              product.deleted_at === null ? (
                <Card
                  key={index}
                  onClick={() => {
                    setProductID(product.id);
                    handleShow(true);
                  }}
                  style={{ maxWidth: "15rem", minWidth: "15rem" }}
                  className={"type_" + type.id + " filter-item mr-3 mb-2"}
                >
                  <Card.Header>
                    {product.title}
                    {language === "lv" ? (
                      <Card.Text style={{ fontSize: "13px" }}>
                        {product.short_desc_lv}
                      </Card.Text>
                    ) : (
                      <Card.Text style={{ fontSize: "13px" }}>
                        {product.short_desc_ru ?? product.short_desc_lv}
                      </Card.Text>
                    )}
                  </Card.Header>
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <ImgContainer
                      src={info.backend.storage_url + product.img_src}
                      alt={product.img_alt}
                    />
                  </Card.Body>
                  <Card.Footer className="px-0 px-sm-3">
                    <Button variant="primary">
                      <FontAwesomeIcon icon={faList} className="mr-2" />
                      <T>know_more</T>
                    </Button>
                  </Card.Footer>
                </Card>
              ) : null
            )
          )}
          <ProductCareModal
            onHide={handleClose}
            show={show}
            productId={productID}
            category={category}
            products={data["products"]}
          />
        </CardGroup>
      </div>
    </Col>
  );
}
export default Products;
