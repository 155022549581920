import React, { useState } from "react";
import { Row, Col, Spinner, Card } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams, useLocation } from "react-router-dom";
import { apiProducts } from "api";
// import { T } from "translations/T";
import Message from "multiuse/Message";
import info from "info";
import ProductModal from "multiuse/modals/ProductModal";
import ImgContainer from "multiuse/ImgContainer";

function Products({ title, description }) {
  const [show, setShow] = useState(false);
  const [productID, setProductID] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let id;
  const location = useLocation();
  const { category, subCategoryId, typeId } = useParams();

  if (typeId === undefined) {
    id = "null";
  } else {
    id = typeId;
  }

  const { isLoading, error, data } = useQuery(
    ["Products", subCategoryId, id],
    () => apiProducts(subCategoryId, id)
  );

  if (isLoading) {
    return (
      <Col
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ opacity: 0.6, minHeight: "22rem" }}
      >
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
      </Col>
    );
  }

  if (error || data.length === 0) {
    return (
      <Col
        className="w-100 align-items-center justify-content-center"
        style={{ minHeight: "22rem" }}
      >
        <h1>{title}</h1>
        <p>{description}</p>
        <Message />
      </Col>
    );
  }

  return (
    <Col>
      <h1>{title}</h1>
      <p>{description}</p>

      <Row className="mt-4">
        {data.map((product) =>
          product.featured && product.deleted_at === null ? (
            <Col
              key={product.id}
              xs="4"
              sm="3"
              md="4"
              lg="3"
              xl="2"
              className="px-2"
            >
              <Card
                className={location.pathname === "/event/grass" ? "grass" : ""}
                onClick={() => {
                  setProductID(product.id);
                  handleShow(true);
                }}
              >
                <div>
                  <ImgContainer
                    src={info.backend.storage_url + product.img_src}
                    alt={product.img_alt}
                  />
                </div>
                <Card.Body>
                  <Card.Text>{product.title}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ) : null
        )}
        <ProductModal
          onHide={handleClose}
          show={show}
          productId={productID}
          products={data}
          pathname={location.pathname}
          category={category}
        />
      </Row>
    </Col>
  );
}

export default Products;
