import img1 from "images/client_section/beauty-salon.png";
import img2 from "images/client_section/factory.png";
import img3 from "images/client_section/store.png";
import img4 from "images/client_section/auto.png";
import img5 from "images/client_section/house.png";
import img6 from "images/client_section/kid.png";
import img7 from "images/client_section/restaurant.png";
import img8 from "images/client_section/school.png";
import img9 from "images/client_section/hotel.png";
import img10 from "images/client_section/gym.png";
import img11 from "images/client_section/office.png";

import parquetBig1 from "images/parquet/floor1.png";
import parquetSmall1 from "images/parquet/item1.png";
import parquetBig2 from "images/parquet/floor2.png";
import parquetSmall2 from "images/parquet/item2.png";
import parquetBig3 from "images/parquet/floor3.png";
import parquetSmall3 from "images/parquet/item3.png";
import parquetBig4 from "images/parquet/floor4.png";
import parquetSmall4 from "images/parquet/item4.png";
import parquetBig5 from "images/parquet/floor5.png";
import parquetSmall5 from "images/parquet/item5.png";
import parquetBig6 from "images/parquet/floor6.png";
import parquetSmall6 from "images/parquet/item6.png";
import parquetBig7 from "images/parquet/floor7.png";
import parquetSmall7 from "images/parquet/item7.png";
import parquetBig8 from "images/parquet/floor8.png";
import parquetSmall8 from "images/parquet/item8.png";
import parquetBig9 from "images/parquet/floor9.png";
import parquetSmall9 from "images/parquet/item9.png";

const Info = {
  api: { API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT + "/api" },
  backend: { storage_url: process.env.REACT_APP_API_ENDPOINT + "/storage/" },
  pages: [
    {
      url: "/office",
      translation: "office",
    },
    {
      url: "/home",
      translation: "home",
    },
    {
      url: "/events",
      translation: "events",
    },
    {
      url: "/tools",
      translation: "tools",
    },
    {
      url: "/care-products",
      translation: "care_products",
    },
    {
      url: "/parquet",
      translation: "parquet",
    },
    {
      url: "/contacts",
      translation: "contacts",
    },
  ],
  languages: [
    {
      key: "ru",
      value: "RU",
    },
    {
      key: "lv",
      value: "LV",
    },
  ],
  client_section: [
    {
      title: "salon",
      img: img1,
      up: true,
    },
    {
      title: "factory",
      img: img2,
      up: false,
    },
    {
      title: "store",
      img: img3,
      up: true,
    },
    {
      title: "auto",
      img: img4,
      up: false,
    },
    {
      title: "house",
      img: img5,
      up: true,
    },
    {
      title: "kid",
      img: img6,
      up: false,
    },
    {
      title: "restaurant",
      img: img7,
      up: true,
    },
    {
      title: "hotel",
      img: img8,
      up: false,
    },
    {
      title: "school",
      img: img9,
      up: true,
    },
    {
      title: "gym",
      img: img10,
      up: false,
    },
    {
      title: "client_office",
      img: img11,
      up: true,
    },
  ],
  parquet_img: [
    {
      id: "img1",
      small: parquetSmall1,
      big: parquetBig1,
    },
    {
      id: "img2",
      small: parquetSmall2,
      big: parquetBig2,
    },
    {
      id: "img3",
      small: parquetSmall3,
      big: parquetBig3,
    },
    {
      id: "img4",
      small: parquetSmall4,
      big: parquetBig4,
    },
    {
      id: "img5",
      small: parquetSmall5,
      big: parquetBig5,
    },
    {
      id: "img6",
      small: parquetSmall6,
      big: parquetBig6,
    },
    {
      id: "img7",
      small: parquetSmall7,
      big: parquetBig7,
    },
    {
      id: "img8",
      small: parquetSmall8,
      big: parquetBig8,
    },
    {
      id: "img9",
      small: parquetSmall9,
      big: parquetBig9,
    },
  ],
  tags: {
    index_title: "4metri | Grīdas segumi",
    index_description:
      "Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi.",
    office_title: "4metri | Birojam",
    office_description: "4metri, Grīdas segumi biroju telpām.",
    home_title: "4metri | Mājai",
    home_description: "4metri, Grīdas segumi mājai.",
    event_title: "4metri | Pasākumiem",
    event_description: "4metri, Grīdas segumi pasākumiem.",
    tools_title: "4metri | Instrumentu katalogi",
    tools_description: "4metri, Grīdas segumu ieklāšanas instrumenti.",
    parquet_title: "4metri | Parketa grīdas segumi",
    parquet_description:
      "4metri, Parketa grīdas segumi mājai, birojam un komerctelpām.",
    care_products_title: "4metri | Kopšanas līdzekļi grīdu segumiem",
    care_products_description:
      "4metri, Visa veida kopšanas līdzekļi dažāda veida grīdas segumiem.",
    contacts_title: "4metri | Kontakti",
    contacts_description: "4metri, Mūsu kontakti.",
  },
};

export default Info;
