function GoogleMap() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4354.590396055686!2d24.090804!3d56.92660800000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eed03681919d73%3A0x8dccc1b08f08e0f0!2zVmllbsSrYmFzIGdhdC4gMzgsIFplbWdhbGVzIHByaWVrxaFwaWxzxJN0YSwgUsSrZ2EsIExWLTEwMDQ!5e0!3m2!1sen!2slv!4v1612431357758!5m2!1sen!2slv"
      frameBorder="0"
      title="4metri"
      height="100%"
      width="100%"
      aria-hidden="false"
      tabIndex="0"
    ></iframe>
  );
}

export default GoogleMap;
