import { GET } from "utils/api";
import info from "info";
import axios from "axios";

const _POST = (url, data) => {
  return axios
    .post(info.api.API_ENDPOINT + url, data)
    .then(function (response) {
      return new Promise(function (resolve, reject) {
        resolve(response);
      });
    })
    .catch(function (error) {
      return new Promise(function (resolve, reject) {
        if (error.response.status === 400) {
          reject(error.response.data);
        }
        reject(error.response);
      });
    });
};
// POST
const apiContactForm = (data) => {
  return _POST("/contactForm", data);
};

// GET
const _GET = (url) => {
  return axios
    .get(info.api.API_ENDPOINT + url)
    .then(function (response) {
      return new Promise(function (resolve, reject) {
        resolve(response.data);
      });
    })
    .catch(function (error) {
      return new Promise(function (resolve, reject) {
        reject(error.response);
      });
    });
};

const apiAddress = () => {
  return _GET("/address");
};

const apiClients = () => {
  return _GET("/clients");
};

const apiTools = () => {
  return _GET("/tools");
};

const apiBusinessInfo = () => {
  return _GET("/business-info");
};

const apiAllSubcategories = (name) => {
  return GET("/all-subcategories/" + name);
};

const apiAllTypes = (subcategoryId) => {
  return GET("/all-types/" + subcategoryId);
};

const apiTypeData = (id, isEvents) => {
  return GET("/type-data/" + id + "/" + isEvents);
};

const apiCareProducts = () => {
  return _GET("/care-products");
};

const apiProducts = (subId, typeId) => {
  return GET("/products/" + subId + "/" + typeId);
};

const apiParquetData = (subId, id) => {
  return GET("/parquet-data/" + subId + "/" + id);
};

const apiConsultant = (category) => {
  return GET("/consultant/" + category);
};

export {
  apiAddress,
  apiClients,
  apiProducts,
  apiCareProducts,
  apiTools,
  apiContactForm,
  apiAllSubcategories,
  apiAllTypes,
  apiTypeData,
  apiConsultant,
  apiParquetData,
  apiBusinessInfo,
};
