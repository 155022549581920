import { Alert, Container, Row } from "react-bootstrap";
// import Tags from "multiuse/helmet/MetaTags";
// import tagTexts from "static";
import { T } from "translations/T";

// let title = tagTexts.meta_tag_texts.error_title;
// const content = tagTexts.meta_tag_texts.main_content;

function Error404() {
  return (
    <Row
      className="h-100 align-items-center"
      style={{
        backgroundColor: "#f5b200",
        minHeight: "70vh",
      }}
    >
      <Container>
        {/* <Tags title={title} content={content} /> */}
        <Row className="error-404 align-items-center justify-content-center w-100">
          <Alert
            style={{
              backgroundColor: "#fcf8e3",
              borderColor: "#fcf8e3",
              color: "#8a6d3b",
            }}
            className="w-100 text-center"
          >
            <T>error_404</T>
          </Alert>
        </Row>
      </Container>
    </Row>
  );
}

export default Error404;
