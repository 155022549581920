import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Loader from "multiuse/Loader";
import Item from "./Item";
import "./style.scss";

function Slider(props) {
  if (!props.ready) {
    return <Loader size="lg" />;
  }
  if (props.clients.length === 0) {
    return null;
  }

  const handleDragStart = (e) => e.preventDefault();

  let items = [];

  const itemsPerSlide = {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1024: {
      items: 4,
    },
    1200: {
      items: 5,
    },
    1500: {
      items: 6,
    },
  };

  let length = props.clients.length;

  props.clients.map(
    (client, index) =>
      (items[index] = (
        <Item
          key={index}
          length={length}
          img={client.img_src}
          alt={client.img_alt}
          title={client.title}
          onDragStart={handleDragStart}
        />
      ))
  );

  return (
    <AliceCarousel
      mouseTracking
      swipeDelta={70}
      items={items}
      responsive={itemsPerSlide}
      className="w-100 px-4"
      autoPlay={true}
      autoPlayInterval={3000}
      animationType="fadeout"
    />
  );
}

export default Slider;
