import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import Message from "multiuse/Message";
import ProductModal from "multiuse/modals/ProductModal";
import { useParams, useLocation } from "react-router-dom";
import Spinner from "multiuse/SpinnerLoader";
import info from "info";
import ImgContainer from "multiuse/ImgContainer";

function ParquetItem({ data, isLoading, error }) {
  const { category } = useParams();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [productID, setProductID] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (isLoading) {
    return (
      <Row>
        <Col
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ opacity: 0.6, minHeight: "22rem" }}
        >
          <Spinner />
        </Col>
      </Row>
    );
  }

  if (error) {
    return (
      <Row>
        <Col
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ minHeight: "22rem" }}
        >
          <Message />
        </Col>
      </Row>
    );
  }
  if (data["products"].length === 0) {
    return <Message />;
  }
  return (
    <Row>
      {data["products"].map((product) =>
        product.featured ? (
          <Col key={product.id} xs="6" md="4" lg="3">
            <Card
              onClick={() => {
                setProductID(product.id);
                handleShow(true);
              }}
            >
              <ImgContainer
                src={info.backend.storage_url + product.img_src}
                alt={product.img_alt}
              />
              <Card.Body>
                <Card.Text>{product.title}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ) : null
      )}

      <ProductModal
        onHide={handleClose}
        show={show}
        productId={productID}
        products={data["products"]}
        pathname={location.pathname}
        category={category}
      />
    </Row>
  );
}

export default ParquetItem;
