import React from "react";
import { Link, useParams } from "react-router-dom";
import { Col } from "react-bootstrap";

function Item({ title, slug, id }) {
  const { category, subCategory, subCategoryId } = useParams();
  return (
    <Col md={4} lg={3}>
      <Link
        to={
          "/" +
          category +
          "/" +
          subCategory +
          "/" +
          subCategoryId +
          slug +
          "/" +
          id
        }
      >
        <div
          className="d-flex align-items-center justify-content-center p-3 my-2 bg-warm-white text-uppercase font-weight-bold tc-brown shadow font-20 up-on-hover"
          style={{ height: "8rem" }}
        >
          <span className="text-center">{title}</span>
        </div>
      </Link>
    </Col>
  );
}

export default Item;
