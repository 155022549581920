import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

function Consultant(props) {
  return (
    <Col>
      <Row className="w-100">
        <Col xs="12">
          <p className="d-block d-sm-flex align-items-center text-center title mx-auto">
            <i className="d-none d-sm-flex">
              <FontAwesomeIcon icon={faUser} />
            </i>
            {props.name}
          </p>
        </Col>
        <Col
          xs="12"
          className="d-flex d-sm-block justify-content-center align-items-center"
        >
          <div className="d-flex align-items-center mb-3">
            <a href={"tel:" + props.phone}>
              <FontAwesomeIcon icon={faMobileAlt} />
            </a>
            <p className="d-none d-sm-block mb-0">{props.phone}</p>
          </div>
          <div className="d-flex align-items-center mb-3">
            <a href={"mailto:" + props.email}>
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <p className="d-none d-sm-block mb-0">{props.email}</p>
          </div>
        </Col>
      </Row>
      <hr></hr>
    </Col>
  );
}

export default Consultant;
