import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import { Link as Smooth } from "react-scroll";
import Logo from "images/logo/logo.png";
// import LangDropdown from "./components/lang_dropdown/Lang";
import NavLink from "./components/nav_link/NavLink";
import MobileMenu from "./components/mobile_menu/MobileMenu";
import info from "info";
import routes from "routes";
import "./style.scss";

function Header() {
  const [navbar, setNavbar] = useState(false);
  const [mobile, setMobile] = useState(false);

  const location = useLocation();

  const addActive = () => {
    if (window.scrollY >= 1) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const addMobile = () => {
    if (window.innerWidth < 992) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", addActive);
    return () => window.removeEventListener("scroll", addActive);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", addMobile);
    return () => window.removeEventListener("resize", addMobile);
  }, []);

  return (
    <div
      className={
        navbar || mobile || window.innerWidth < 992
          ? "header py-2 active"
          : "header py-3"
      }
    >
      <Container fluid>
        <Row className="align-items-center">
          <Col>
            {location.pathname === routes.index ? (
              <Smooth
                to={routes.index}
                smooth="true"
                offset={-50}
                duration={500}
              >
                <img
                  src={Logo}
                  alt="4metri - Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība."
                  className="pr-2"
                ></img>
              </Smooth>
            ) : (
              <Link to={routes.index}>
                <img
                  src={Logo}
                  alt="4metri - Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība."
                  className="pr-2"
                ></img>
              </Link>
            )}
          </Col>
          <Col lg="9" className="d-none d-lg-inline-block">
            <Navbar className="d-none d-lg-block text-center p-0">
              {info.pages.map((page, index) => (
                <NavLink key={index} name={page.translation} url={page.url} />
              ))}
            </Navbar>
          </Col>
          <Col>
            <div className="d-none d-lg-block text-right">
              {/* <LangDropdown /> */}
            </div>
            <div className="d-block d-lg-none">
              <MobileMenu />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
