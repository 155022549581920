import { Row, Col, Card } from "react-bootstrap";
import { T } from "translations/T";
import info from "info";
import "./style.scss";
import ImgContainer from "multiuse/ImgContainer";

function Clients() {
  return (
    <Row className="clients-section justify-content-center align-items-center d-none d-md-flex">
      <Col style={{ minHeight: "100vh" }}>
        <Row className="justify-content-center mt-5">
          <h1 className="client-title">
            <T>client_title</T>
          </h1>
        </Row>
        <Row
          className="first-row justify-content-center align-items-end"
          style={{ height: "50vh" }}
        >
          {info.client_section.map((item, index) =>
            index < 6 ? (
              <Card key={index} className={item.up ? "up" : ""}>
                <Card.Title>
                  <T>{item.title}</T>
                </Card.Title>
                <div className="card-wrap">
                  <ImgContainer
                    src={item.img}
                    alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
                  />
                </div>
              </Card>
            ) : null
          )}
        </Row>
        <Row
          className="second-row justify-content-center align-items-end"
          style={{ height: "30vh" }}
        >
          {info.client_section.map((item, index) =>
            index > 5 ? (
              <Card key={index} className={item.up ? "up" : ""}>
                <Card.Title>
                  <T>{item.title}</T>
                </Card.Title>
                <div className="card-wrap">
                  <ImgContainer
                    src={item.img}
                    alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
                  />
                </div>
              </Card>
            ) : null
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default Clients;
