import { Row } from "react-bootstrap";
import Loader from "multiuse/Loader";
import Message from "multiuse/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

function ConsultantContact({ email, phone, ready }) {
  if (ready) {
    return <Loader size="md" />;
  }
  if (!email && !phone) {
    return <Message />;
  }

  return (
    <Row className="align-items-center justify-content-center justify-content-md-start">
      <a href={"tel:" + phone}>
        <i className="contact-icon mr-2 ml-auto">
          <FontAwesomeIcon icon={faMobileAlt} />
        </i>
      </a>
      <a href={"tel:" + phone} className="d-none d-lg-block mr-2">
        {phone}
      </a>

      <a href={"mailto:" + email}>
        <i className="mr-2 contact-icon">
          <FontAwesomeIcon icon={faEnvelope} />
        </i>
      </a>
      <a href={"mailto:" + email} className="d-none d-lg-block">
        {email}
      </a>
    </Row>
  );
}

export default ConsultantContact;
