import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import ConsultationModal from "multiuse/modals/ConsultationModal";
import ConsultationButton from "multiuse/consultant/Button";
import Consultant from "multiuse/consultant/Consultant";
import ParquetBed from "./components/parquet/ParquetBed";
import SubCategories from "./SubCategories";
import OfficeImg from "images/category_img/office.jpg";
import HomeImg from "images/category_img/home.jpg";
import EventsImg from "images/category_img/events.jpg";
import { T } from "translations/T";
import Tags from "multiuse/helmet/MetaTags";
import { useParams } from "react-router-dom";
import YellowContainer from "app/components/YellowContainer";
import "./category.scss";
import ImgContainer from "multiuse/ImgContainer";

function Category() {
  const { category } = useParams();
  const [modalShow, setModalShow] = useState(false);

  return (
    <YellowContainer className="category">
      <Tags />
      <Consultant category={category} />
      <div className="pb-5">
        <ConsultationButton show={setModalShow} />
        <ConsultationModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
      {category === "parquet" ? (
        <>
          <ParquetBed />{" "}
          <Row>
            <SubCategories category={category} />
          </Row>
        </>
      ) : (
        <Row className="align-items-center">
          <Col lg="5" className="mb-1 d-none d-lg-block">
            <Card className="big-card align-items-center justify-content-center ">
              {category === "office" ? (
                <ImgContainer
                  src={OfficeImg}
                  alt="Office category section image"
                />
              ) : category === "home" ? (
                <ImgContainer src={HomeImg} alt="Home category section image" />
              ) : category === "events" ? (
                <ImgContainer
                  src={EventsImg}
                  alt="Event category section image"
                />
              ) : null}
              <Card.ImgOverlay className="d-flex align-items-end justify-content-center">
                <Card.Title className="m-0">
                  <T>floor_coating</T> <T>{category}</T>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col className="p-0 mt-4 mt-lg-0">
            <Row className="align-items-center">
              <SubCategories category={category} />
            </Row>
          </Col>
        </Row>
      )}
    </YellowContainer>
  );
}

export default Category;
