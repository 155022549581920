import React, { useContext } from "react";
import Loader from "multiuse/Loader";
import { Col, Card } from "react-bootstrap";
import { LanguageContext } from "LanguageProvider";
import { useQuery } from "react-query";
import { apiTools } from "api";
import info from "info";
import ImgContainer from "multiuse/ImgContainer";

function Item({ category }) {
  const { isLoading, error, data } = useQuery(["section_" + category], () =>
    apiTools()
  );
  const { language } = useContext(LanguageContext);

  if (isLoading) {
    return (
      <Col md="6" lg="4" className="filter-item  mb-2">
        <Card
          className="big-card type-card d-flex align-items-center justify-content-center m-0"
          style={{ minHeight: "12rem" }}
        >
          <Loader size="md" />
        </Card>
      </Col>
    );
  }
  if (data.length === 0 || error) {
    return (
      <p
        className="text-center w-100"
        style={{ color: "white", letterSpacing: ".9px" }}
      >
        Instrumentu katalogi vēl nav pievienoti!
      </p>
    );
  }

  return (
    <>
      {data.map((tool, index) =>
        tool.featured ? (
          <Col key={index} md="6" lg="4" className="mb-2">
            <a
              href={info.backend.storage_url + tool.pdf_src}
              target="_blank"
              rel="noreferrer"
            >
              <Card
                className="big-card type-card d-flex align-items-center justify-content-center m-0"
                style={{ minHeight: "12rem" }}
              >
                <ImgContainer
                  src={info.backend.storage_url + tool.img_src}
                  alt={tool.img_alt}
                  style={
                    !tool.img_src
                      ? { opacity: 0, maxHeight: "12rem" }
                      : { opacity: 1, maxHeight: "12rem" }
                  }
                />

                <Card.ImgOverlay className="d-flex align-items-center justify-content-center">
                  {language === "lv" ? (
                    <Card.Title className="m-0">{tool.title_lv}</Card.Title>
                  ) : (
                    <Card.Title className="m-0">
                      {tool.title_ru ?? tool.title_lv}
                    </Card.Title>
                  )}
                </Card.ImgOverlay>
              </Card>
            </a>
          </Col>
        ) : null
      )}
    </>
  );
}

export default Item;
