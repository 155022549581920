import React from "react";
import { Link } from "react-router-dom";
import OfficeImg from "images/category_img/1.png";
import HomeImg from "images/category_img/2.png";
import EventImg from "images/category_img/3.png";
import ImgContainer from "multiuse/ImgContainer";
import { T } from "translations/T";
import { Col, Row } from "react-bootstrap";
import "./style.scss";

function CategoryAnimation(props) {
  return (
    <Row className="category-section d-none d-sm-flex">
      <Col
        className="p-0 mt-auto left"
        onMouseEnter={() => {
          props.setHover_1(true);
          props.setHover_2(false);
          props.setHover_3(false);
        }}
      >
        <Link to="/office">
          <div className={props.hover1 ? "img-1 active" : "img-1"}>
            <ImgContainer
              src={OfficeImg}
              alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
              className="img-fluid"
            />
          </div>
          <p
            className={
              props.hover1 ? "text-center mt-4 active" : "text-center mt-4"
            }
          >
            <T>office</T>
          </p>
        </Link>
      </Col>
      <Col
        className="p-0 middle"
        onMouseEnter={() => {
          props.setHover_2(true);
          props.setHover_1(false);
          props.setHover_3(false);
        }}
      >
        <Link to="/home">
          <div className={props.hover2 ? "img-2 active" : "img-2"}>
            <ImgContainer
              src={HomeImg}
              alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
              className="img-fluid"
            />
          </div>
          <p
            className={
              props.hover2
                ? "mt-auto text-center mt-4 active"
                : "mt-auto text-center mt-4"
            }
          >
            <T>home</T>
          </p>
        </Link>
      </Col>
      <Col
        className="p-0 mt-auto right"
        onMouseEnter={() => {
          props.setHover_3(true);
          props.setHover_2(false);
          props.setHover_1(false);
        }}
      >
        <Link to="/events">
          <div className={props.hover3 ? "img-3 active" : "img-3"}>
            <ImgContainer
              src={EventImg}
              alt="Grīgas segumu un kopšanas līdzekļu izplatīšana un tirdzniecība. Segumi pasākumiem, publiskām telpām, morectelpām un privātām telpām. Plaša klāsta parkets, linolejs, mīkstie segumi, segumu tīrīšanas līdzekļi."
              className="img-fluid"
            />
          </div>
          <p
            className={
              props.hover3 ? "text-center mt-4 active" : "text-center mt-4"
            }
          >
            <T>events</T>
          </p>
        </Link>
      </Col>
    </Row>
  );
}

export default CategoryAnimation;
