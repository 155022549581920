import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { T } from "translations/T";
import "./style.scss";

function CustomBreadcrumb() {
  const { category, subCategory, subCategoryId, type, id } = useParams();

  return (
    <Breadcrumb>
      <Breadcrumb.Item
        linkAs={Link}
        active={subCategory ? false : true}
        linkProps={{ to: "/" + category }}
      >
        <T>{category}</T>
      </Breadcrumb.Item>
      {subCategory && type !== subCategory ? (
        <Breadcrumb.Item
          active={type ? false : true}
          linkAs={Link}
          linkProps={{
            to: "/" + category + "/" + subCategory + "/" + subCategoryId,
          }}
        >
          <T>{subCategory.replace("-", "_")}</T>
        </Breadcrumb.Item>
      ) : null}

      {type ? (
        <Breadcrumb.Item
          active
          linkProps={{ to: "/" + category + "/" + subCategory + "/" + type }}
        >
          <T>{type.replace("-", "_")}</T>
        </Breadcrumb.Item>
      ) : null}

      {id ? (
        <Breadcrumb.Item
          active
          linkProps={{ to: "/" + category + "/" + subCategory + "/" + id }}
        >
          <T>{id.replace("-", "_")}</T>
        </Breadcrumb.Item>
      ) : null}
    </Breadcrumb>
  );
}

export default CustomBreadcrumb;
